<template>
  <v-app id="inspire">
    <v-app-bar class="header">
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        color="white"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>
        <v-img
          :src="require('@/assets/img/logo-w.png')"
          max-height="28"
          width="80"
        ></v-img>
      </v-toolbar-title>
      <template v-slot:append>
        <div class="user-header">
          <v-icon class="icon-user-header" icon="mdi-account-box"></v-icon>
          <span>{{ user }}</span>

          <v-menu>
            <template v-slot:activator="{ props }">
              <!-- <v-btn icon="mdi-dots-vertical" ></v-btn> -->
              <v-btn icon="mdi-dots-vertical" v-bind="props"></v-btn>
            </template>

            <v-list>
              <v-list-item min-width="150" @click="perfil()">
                <v-list-item-title> Perfil </v-list-item-title>
              </v-list-item>
              <v-list-item min-width="150" @click="logout()">
                <v-list-item-title> Sair </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-app-bar>
    <MenuItem :controle="drawer" @toggleMenu="drawer = $event"></MenuItem>

    <v-main class="main">
      <v-container>
        <v-breadcrumbs :items="items"></v-breadcrumbs>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";

import MenuItem from "@/components/MenuItem.vue";
import { getUserInfo } from "@/util/auth";
import { useRouter } from "vue-router";

import AuthService from "@/services/auth.service";

export default defineComponent({
  name: "App",
  components: {
    MenuItem,
  },
  data: () => ({
    drawer: null,
    user: "",
  }),
  setup() {
    const router = useRouter();

    const logout = () => {
      AuthService.logout();
      router.push("/login");
    };

    const perfil = () => {
      router.push("/perfil");
    };

    const store = useStore();

    const items = computed(() => store.state.breadcrumbs.items);
    return {
      logout,
      items,
      perfil,
    };
  },
  created() {
    const data = getUserInfo();
    this.user = data.name.split(" ")[0];
  },
});
</script>
<style scoped>
.main {
  background: #e5e5e5;
}
.footer-dash {
  z-index: 1006;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
}
.header {
  background-color: #0091d5 !important;
}
.user-header {
  color: #fff;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
}
.user-header span {
  margin-right: 20px;
}
.icon-user-header {
  margin-right: 10px;
}
</style>