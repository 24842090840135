import { createStore } from 'vuex'
import { breadcrumbs } from './breadcrumbs'
import { edicoes } from './edicoes'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    breadcrumbs,
    edicoes
  }
})
