/**
 * plugins/webfontloader.js
 *
 * webfontloader documentation: https://github.com/typekit/webfontloader
 */

export async function loadFonts() {
    const webFontLoader = await import(/* webpackChunkName: "webfontloader" */'webfontloader')

    webFontLoader.load({
        google: {
            families: ['Roboto:100,300,400,500,700,900&display=swap', 'Fira+Sans:300,300italic,400,400italic,500,500italic,700,700italic'],
        },
    })
}
