import http from '@/http'

class AuthService {
    login({ cpf, password }) {
        return http
            .post('public/colaborador/login', {
                cpf,
                password,
            })
            .then((response) => {
                if (response.data.token) {
                    localStorage.setItem('colaborador-cuco', JSON.stringify(response.data.token))
                }
                return response.data
            })
    }

    logout() {
        localStorage.removeItem('colaborador-cuco')
    }
}

export default new AuthService()
