import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { loadFonts } from './plugins/webfontloader'
loadFonts()
// Vuetify
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import 'vuetify/styles'

import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// Translations provided by Vuetify
import { pt } from 'vuetify/locale'

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'mdi', // This is already the default value - only for display purposes
    },
    locale: {
        locale: 'pt',
        messages: { pt }
    }
})

import VueMask from '@devindex/vue-mask';

createApp(App).use(store).use(router).use(vuetify).use(VueMask).component("v-select-complete", vSelect).mount('#app')
