

export const breadcrumbs = {
    state: {
        items: []
    },
    mutations: {
        SET_BREADCRUMBS(state, items) {
            state.items = items
        }
    },
    actions: {
        setBreadcrumbs({ commit }, items) {
            commit('SET_BREADCRUMBS', items)
        }
    },
}