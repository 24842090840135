<template>
  <v-navigation-drawer
    theme="dark"
    color="#343232"
    app
    width="300"
    v-model="controle_aux"
  >
    <v-list rounded>
      <div class="group-menu">
        <v-list-subheader>Dashboard</v-list-subheader>

        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :value="item"
          rounded="xl"
          class="btn-menu-dash"
          @click="navigate(item.link)"
          :active="activeBtn(item.path)"
        >
          <template v-slot:prepend>
            <v-icon class="icon-btn-list-menu" :icon="item.icon"></v-icon>
          </template>

          <v-list-item-title class="text-btn-list-menu">{{
            item.text
          }}</v-list-item-title>
        </v-list-item>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { getUserInfo } from "@/util/auth";

export default {
  data: () => ({
    controle_aux: false,
    drawer: null,
    items: [],
  }),
  props: {
    controle: {
      required: true,
      default: false,
    },
  },
  methods: {
    navigate(to) {
      this.$router.push(to);
    },
    activeBtn(path) {
      if (this.$route.path.split("/")[1] === path) {
        return true;
      }
      return false;
    },
    menutype() {
      const user = getUserInfo();

      if (user.type) {
        this.items = [
          { text: "Home", icon: "mdi-home", link: "/home", path: "home" },
          {
            text: "Edições",
            icon: "mdi-file",
            link: "/edicoes",
            path: "edicoes",
          },
          {
            text: "Premiações",
            icon: "mdi-file-certificate",
            link: "/premiacoes",
            path: "premiacoes",
          },
          {
            text: "Professores",
            icon: "mdi-school",
            link: "/professores",
            path: "professores",
          },
          {
            text: "Perfil",
            icon: "mdi-account",
            link: "/perfil",
            path: "perfil",
          },
        ];
      } else {
        this.items = [
          { text: "Home", icon: "mdi-home", link: "/home", path: "home" },
          {
            text: "Edições",
            icon: "mdi-file",
            link: "/edicoes",
            path: "edicoes",
          },
          {
            text: "Premiações",
            icon: "mdi-file-certificate",
            link: "/premiacoes",
            path: "premiacoes",
          },
          {
            text: "Perfil",
            icon: "mdi-account",
            link: "/perfil",
            path: "perfil",
          },
        ];
      }
    },
  },
  async created() {
    this.menutype();
    this.controle_aux = this.controle;
    // let dadosUser = JSON.parse(localStorage.getItem("user")) || [];
    // this.tipoAluno = dadosUser.tipo;
    // if(dadosUser.tipo === 'ALUNO'){
    //   this.items = [
    //     { title: "Disciplinas", icon: "mdi-paperclip", link: "/aluno/disciplinas" },
    //     { title: "Perfil", icon: "mdi-clipboard-account", link: "/perfil" },
    //   ];
    // }
    // else{
    //   this.items = [
    //     { title: "Disciplinas", icon: "mdi-paperclip", link: "/" },
    //     { title: "Questionários", icon: "mdi-help", link: "/quiz" },
    //     { title: "Perfil", icon: "mdi-clipboard-account", link: "/perfil" },
    //   ];
    // }
  },
  watch: {
    controle_aux(newValue) {
      this.$emit("toggleMenu", newValue);
    },
    controle() {
      this.controle_aux = this.controle;
    },
  },
};
</script>

<style scoped>
.text-btn-list-menu {
  font-size: 0.9rem;
  font-weight: 500;
}
.icon-btn-list-menu {
  margin-right: 18px !important;
  color: #fff;
}
.group-menu {
  padding: 6px;
  display: block;
}
.btn-menu-dash {
  color: #fff;
}
.group-menu .btn-menu-dash:not(:last-child):not(:only-child) {
  margin-bottom: 8px;
}
</style>